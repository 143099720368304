<template>
  <div class="book">
    <div class="book-container">
      <div class="book-container__load-name--responsive" v-if="chosenLoad">
        <span> {{ $t("carriers.quoteNumber") }} {{ chosenLoad.name }} </span>
      </div>
      <div class="book-container__load-name" v-if="chosenLoad">
        <span> {{ $t("carriers.quoteNumber") }} {{ chosenLoad.name }} </span>
      </div>
      <div class="book-container__content">
        <b-tabs card no-fade :class="tabsDisabled ? 'disablePointerEvents' : ''">
          <div class="quotebook-tab-container" v-for="(tab, index) in loadTypes" :key="index">
            <b-tab
              v-if="tab.enabled && tab.quoteBook"
              :title="tab.title"
              :active="loadTypes[tab.id].activeTab"
              :disabled="handleDisabledTab(tab.id)"
              @click="selectQuoteType(tab.id)"
            >
              <b-card-text>
                <CreateLoad
                  v-if="currentQuoteTab === tab.id"
                  :modeInfo="tab"
                  :key="clearLoadInfo"
                  @creatingLoad="creatingLoadHandler"
                />
                <router-view />
              </b-card-text>
            </b-tab>
          </div>
        </b-tabs>
      </div>
      <div class="book-container__content"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import onResize from "@/Extend/onResize";
import loadTypes from "./modes";
import CreateLoad from "./LoadCreation/CreateLoad.vue";

export default {
  name: "Book",
  mixins: [onResize],
  components: {
    CreateLoad,
  },
  data() {
    return {
      accessorials: [],
      loadTypes: null,
      currentQuoteTab: null,
      key: 0,
      tabsDisabled: false,
    };
  },
  computed: {
    ...mapGetters({
      chosenLoad: "load/getChosenLoad",
      clearLoadInfo: "load/getClearLoadInformation",
    }),
  },
  async created() {
    this.loadTypes = { ...loadTypes };
    if (this.$route.params.comesFromCarriers) {
      this.setQuoteType();
    }
    this.handleActiveTab(this.$route.params.quoteType);
  },
  methods: {
    creatingLoadHandler(data) {
      this.tabsDisabled = data;
    },
    setQuoteType() {
      /* eslint-disable implicit-arrow-linebreak */
      const chosenLoad = this.$store.getters["load/getChosenLoad"];
      const tabName = Object.keys(this.loadTypes)
        .reverse()
        .find(
          (el) =>
            this.loadTypes[el].modeId === chosenLoad.mode_id &&
            this.loadTypes[el].multiservice === chosenLoad.multiservice
        );
      this.$router
        .replace({
          name: "QuoteBook",
          params: {
            quoteType: tabName,
            ...(this.$route.params.loadId && {
              loadId: this.$route.params.loadId,
            }),
            ...(this.$route.params.comesFromCarriers && {
              comesFromCarriers: this.$route.params.comesFromCarriers,
            }),
          },
        })
        .catch((error) => {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        });
    },
    handleActiveTab(tabName) {
      this.currentQuoteTab = tabName;
      const keys = Object.keys(this.loadTypes);
      keys.forEach((el) => {
        if (el.includes(tabName)) {
          this.loadTypes[el].activeTab = true;
        } else {
          this.loadTypes[el].activeTab = false;
        }
      });
    },
    handleDisabledTab(tabName) {
      if (this.$route.name.toLowerCase() !== "carriersinfo") {
        return false;
      }
      if (
        // eslint-disable-next-line operator-linebreak
        this.$route.name.toLowerCase() === "carriersinfo" &&
        this.$route.params.quoteType !== tabName
      ) {
        return true;
      }
      return null;
    },
    selectQuoteType(tabName) {
      if (this.tabsDisabled || this.currentQuoteTab === tabName) {
        return;
      }
      this.$store.commit("load/accessorials/resetChosenAccessorials");
      this.currentQuoteTab = tabName;
      this.handleActiveTab(tabName);
      this.changeView(tabName);
    },
    changeView(quoteType) {
      if (this.$route.params.quoteType !== quoteType) {
        this.$router
          .push({
            name: "QuoteBook",
            params: {
              quoteType,
              ...(this.$route.params.loadId && {
                loadId: this.$route.params.loadId,
              }),
              ...(this.$route.params.comesFromCarriers && {
                comesFromCarriers: this.$route.params.comesFromCarriers,
              }),
            },
          })
          .catch((error) => {
            if (error.name !== "NavigationDuplicated") {
              throw error;
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.book-container {
  padding-top: 1.5rem;
  width: 97%;
  height: 100%;
  margin: 0px auto;
  &__content {
    padding-bottom: 20px;
  }
  &__load-name {
    color: $color-primary-company;
    font-weight: bold;
    position: -webkit-sticky; /* Safari */
    position: relative;
    top: 0px;
    z-index: 4;
    span {
      position: absolute;
      right: 20px;
      top: 30px;
    }
    &--responsive {
      text-align: end;
      color: $color-primary-company;
      font-weight: bold;
      @media (min-width: 1131px) {
        display: none;
      }
    }
    @media (max-width: 1130px) {
      display: none;
    }
  }
  &__title {
    @include title-views(1.75rem);
  }
  &__overlay {
    z-index: 10;
  }
}

.disablePointerEvents {
  pointer-events: none;
}
///****Tabs styles****\\\\
::v-deep .card-header {
  background-color: $color-white;
  border-bottom: 0px;
  padding: 12px 9.8px;
}
::v-deep .card-body {
  background: $color-gray-light;
  border: 1px solid $color-border-container;
  border-radius: 0px 10px 10px 10px;
  font-family: $font-family-portal;
  padding: 2%;
}
::v-deep .nav-tabs {
  &::-webkit-scrollbar {
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-gray-dark;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-gray-light;
    border: 2.1px solid $color-gray-dark;
    border-radius: 10px;
  }
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}
::v-deep .nav-tabs .nav-link {
  background-color: $color-gray-dark;
  color: $color-white;
  font-family: $font-family-portal;
  font-weight: bold;
  @include font-small-information;
  border: 1px solid $color-border-container;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 15px 15px;
}
::v-deep .nav-item a {
  font-weight: bold !important;
}
::v-deep .nav-tabs .nav-link {
  min-width: 110px;
}
::v-deep .nav-tabs .nav-link.active {
  color: $color-primary-company;
  font-family: $font-family-portal;
  font-weight: bold;
  @include font-small-information;
  background-color: $color-gray-light;
  border: 1px solid $color-border-container;
  border-bottom: 1px solid $color-gray-light;
  padding: 15px 11px;
}
::v-deep .nav-tabs > li {
  &:first-child > a {
    border-top-left-radius: 20px;
  }
  &:last-child > a {
    border-top-right-radius: 20px;
  }
}

///****Responsive styles****\\\\
@media (max-width: 600px) {
  ::v-deep .nav-tabs .nav-link {
    padding: 13px 11px;
  }
  ::v-deep .nav-tabs .nav-link.active {
    padding: 13px 11px;
  }
}
@media (max-width: 550px) {
  ::v-deep .nav-tabs .nav-link {
    padding: 11px 10px;
  }
  ::v-deep .nav-tabs .nav-link.active {
    padding: 11px 10px;
  }
}
@media (max-width: 515px) {
  ::v-deep .nav-tabs .nav-link {
    padding: 11px 11px;
  }
  ::v-deep .nav-tabs .nav-link.active {
    padding: 11px 11px;
  }
}
@media (max-width: 480px) {
  ::v-deep .nav-tabs .nav-link {
    padding: 11px 11px;
  }
  ::v-deep .nav-tabs .nav-link.active {
    padding: 11px 11px;
  }
}
@media (max-width: 370px) {
  ::v-deep .nav-tabs .nav-link {
    padding: 11px 11px;
  }
  ::v-deep .nav-tabs .nav-link.active {
    padding: 11px 11px;
  }
}
@media (max-width: 370px) {
  ::v-deep .nav-tabs .nav-link {
    padding: 11px 11px;
  }
  ::v-deep .nav-tabs .nav-link.active {
    padding: 11px 11px;
  }
}
@media (max-width: 330px) {
  ::v-deep .nav-tabs .nav-link {
    padding: 11px 11px;
  }
  ::v-deep .nav-tabs .nav-link.active {
    padding: 11px 11px;
  }
}
</style>
